<template>
  <div class="wrap">
    <div class="iconBox">
      <img src="../../assets/newegg/image/new-return.png" alt="" class="rule"  @click="close">
      <div class="balance">
        <img
        src="../../assets/treasureBox/image/title.png"
        alt=""
        class="balance-img"
        />
      </div>
      <img  src="../../assets/treasureBox/image/help.png"  alt=""  class="closeGame" @click="showRule = true" />
    </div>
    <div class="box">
      <div>
        <img
          src="../../assets/treasureBox/image/treasurebox.png"
          alt=""
          class="treasurebox"
        />
      </div>

      <div class="btns">
        <div @click="batchPlay('one')" class="playBtn" :class="{ one: oneImg }">
          <span>1次</span>
          <div>{{priceNum}}橙币</div>
        </div>
        <div @click="batchPlay('five')" class="playBtn" :class="{ five: fiveImg }">
          <span>5次</span>
          <div>{{priceNum * 5}}橙币</div>
        </div>
        <div @click="batchPlay('ten')" class="playBtn" :class="{ ten: tenImg }">
          <span>10次</span>
          <div>{{priceNum * 10}} 橙币</div>
        </div>
    
        <div
          @click="batchPlay('fifty')"
          class="playBtn"
          :class="{ fifty: fiftyImg }"
        >
          <span>50次</span>
          <div>{{priceNum * 50}} 橙币</div>
        </div>
      </div>
    </div>

    <div class="bgGIf" v-if="showEggGif">
      <img :src="bgGIf[catchDom]" alt="" class="gifAnimation" />
    </div>

    <audio id="audio" ref="audio">
      <source src="../../assets/treasureBox/video/open.wav" type="audio/wav" />
    </audio>
    <rule
      :content="dataObj['gold']['remark']"
      v-show="showRule"
      @close="showRule = false"
    />
    <prize v-if="showPrize" :prize="prize" @close="reset" />
    <div class="maskBox" v-if="confirmUnboxing">
      <div class="maskBox-title">是要开{{ playNum }}次箱子吗?</div>
      <div class="maskBox-btn">
        <button @click="confirmUnboxing = false">取消</button>
        <button @click="determineHandle">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/treasureBox/css/index.css";
import rule from "@/components/rule";
import prize from "@/components/prize";
import { Toast } from "vant";

import tdImg from "../../assets/newegg/image/new-egg-one.png";
import jdImg from "../../assets/newegg/image/new-egg-two.png";
import ydImg from "../../assets/newegg/image/new-egg-three.png";
import bottIcon from "../../assets/newegg/image/bottIcon.png";

import jdBgGif from "../../assets/newegg/image/new-two.gif";
import ydBgGif from "../../assets/newegg/image/new-three.gif";
import tdBgGif from "../../assets/newegg/image/new-one.gif";

import activeImg from "../../assets/newegg/image/new-hammer.png";

export default {
  components: {
    prize,
    rule,
  },
  data() {
    return {
      showRule: false,
      showPrize: false,
      gold: 0,
      prize: null,
      tdImg,
      jdImg,
      ydImg,
      bottIcon,
      confirmUnboxing: false,
      relevanceObj: {
        copper: tdImg,
        gold: jdImg,
        silver: ydImg,
      },

      catchDom: "gold",
      requestCompleted: true,
      oneImg: true,
      fiftyImg: false,
      tenImg: false,
      fiveImg:false,
      num: "ten",
      dataObj: {
        treasure:{},
        gold:{}
      },
      activeImg,
      playNum: "1",
      showEggGif: false,
      bgGIf: {
        gold: jdBgGif,
        silver: ydBgGif,
        copper: tdBgGif,
      },
      priceNum:''
    };
  },
  mounted() {
    this.getInfo();
  },
  watch: {
    "globalData.XPLATFORM": {
      handler(newVal) {
        newVal && this.getInfo();
      },
    },
  },

  methods: {
    getInfo() {
      if (this.globalData.XPLATFORM) {
        this.$http
          .get("lottery-game/info", {
            params: { code: "treasure_box" },
            headers: {
              "X-PLATFORM": this.globalData.XPLATFORM,
            },
          })
          .then((res) => {
            this.dataObj.treasure = res.data;
            this.gold = res.data["gold"];
            this.dataObj.gold.remark = res.data.remark;
            this.priceNum = res.data.price
          });
      }
    },
    reset() {
      this.$refs.audio.pause();
      $("#" + this.catchDom).attr("src", this.relevanceObj[this.catchDom]);
      this.showPrize = false;
      // this.isShowImg = true;
      this.requestCompleted = true;
      this.getInfo();
    },
    play() {
      //   this.isShowImg = false;
      const limit = this.num == "one" ? "price" : this.num + "_price";
      if (parseInt(this.gold) < parseInt(this.dataObj.treasure[limit])) {
        return Toast.fail({ message: "余额不足", position: "bottom" });
      }
      // this.catchDom = dom;
      // $('#' + dom).attr('src', this.gifObj[dom]);
      this.getData(this.num, 'treasure_box');
    },
    batchPlay(type) {
      if (type == "one") {
        this.num = 'one';
        this.oneImg = true;
        this.fiveImg = false;
        this.tenImg = false;
        this.fiftyImg = false;
        this.playNum='1'
        this.confirmUnboxing=true
      } else if (type == "fifty") {
        this.num = "fifty";
        this.oneImg = false;
        this.five = false;
        this.tenImg = false;
        this.fiftyImg = true;
        this.playNum='50'
        this.confirmUnboxing=true
      } else if (type == "ten") {
        this.num = "ten";
        this.oneImg = false;
        this.fiftyImg = false;
        this.tenImg = true;
        this.fiveImg = false;
        this.playNum='10'
        this.confirmUnboxing=true
      } else if (type == "five") {
        this.num = "five";
        this.oneImg = false;
        this.fiftyImg = false;
        this.tenImg = false;
        this.fiveImg = true;
        this.playNum='5'
        this.confirmUnboxing=true
      }
    },
    getData(type, dom) {
      if (!this.globalData.XPLATFORM) {
        return;
      }
      if (!this.requestCompleted) {
        return;
      }
      this.requestCompleted = false;
      const that = this;
      this.$refs.audio.load();
      this.$refs.audio.play();
      this.$refs.audio.playbackRate = 0.5;
      this.$http
        .post(
          "lottery-game/draw",
          {
            type,
            code: dom,
            room_id: that.globalData.roomId,
          },
          {
            headers: { "X-PLATFORM": this.globalData.XPLATFORM },
          }
        )
        .then((res) => {
          this.prize = res;
          //   setTimeout(() => {
          that.showEggGif = false;
          that.showPrize = true;
          this.$refs.audio.playbackRate = 1;
          //   }, 500);
        })
        .catch((e) => {
          this.reset();
        });
    },
    determineHandle() {
      this.confirmUnboxing = false;
      this.play()
    },
  },
};
</script>

<style scoped></style>
